// remove swipable views and use tabs only

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import webSvg from '../assets/images/services/web.svg';
import mobileSvg from '../assets/images/services/mobile.svg';
import testSvg from '../assets/images/services/test.svg';
import commerceSvg from '../assets/images/services/commerce.svg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ServiceTab = ({children, image, heading}) => {
  return (
    <>
      <Box className="service-content">
        <Box className="service-text">
          <h2>
            { heading }
          </h2>
          { children }
        </Box>
        <img src={image} alt='service-image' />
      </Box>
    </>
  );
}

export const ServicesTabs = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box
      id="services"
      className="container services-tabs"
    >
      <AppBar
        position="static"
        style={{ background: '#2D7068' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="full width tabs example"
        >
          <Tab label="Web Development" {...a11yProps(0)} />
          <Tab label="Application Development" {...a11yProps(1)} />
          <Tab label="Software Testing" {...a11yProps(2)} />
          <Tab label="UI/UX Design" {...a11yProps(3)} />
          <Tab label="E-commerce" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <Box border='1px solid #2D7068' borderTop='none'>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ServiceTab image={webSvg} heading="Web Development">
            <p>
              Stack Hub has diverse experience in developing highly attractive and responsive websites which can be the most cost-effective way of reaching potential customers. We developed customized websites for your business enriched with features and responsive designs that define your core business. Our end-to-end web development services incorporate the design, development, testing, deployment and maintenance of your website.
            </p>
            <ul>
              <li>Custom website development</li>
              <li>Responsive web design</li>
              <li>Web redesign services</li>
              <li>Web consulting services</li>
              <li>Web app integration</li>
              <li>Maintenance & Support</li>
            </ul>
          </ServiceTab>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ServiceTab image={mobileSvg} heading={"Application Development"}>
            <p>
              Stack Hub is offering flawless application development Services for numerous business functions. Our applications are equipped with enhanced UI/UX that increases user engagement. Our teams are experts in building both cross-platform and native applications for renowned platforms like Android, iOS, and others. Businesses wish to have applications so that they could bridge the gap between them and their potential clients and our experts will help develop that ideal application that delivers maximum value.
            </p>
            <ul>
              <li>Custom Application Development Services</li>
              <li>Application Migration Services</li>
              <li>Application integration services</li>
              <li>Application Modernization Services</li>
              <li>Application Testing Services</li>
              <li>Application Management</li>
            </ul>
          </ServiceTab>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ServiceTab image={testSvg} heading={"Software Testing"}>
            <p>
              Every Software needs to be tested in several ways to ensure that it runs smoothly and serves its purpose. Stack Hub offers comprehensive software testing services that ensure the required software is free from errors and is mapped with the customer’s requirements. Thus our QA Engineers use various manual and automated tools for in-depth software testing.
            </p>
            <ul>
              <li>QA consulting</li>
              <li>QA Outsourcing</li>
              <li>Automation Testing</li>
              <li>Manual Testing</li>
              <li>System Testing</li>
              <li>Performance Testing</li>
              <li>Security Testing</li>
            </ul>
          </ServiceTab>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          UI/UX Design
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <ServiceTab image={commerceSvg} heading={"E-commerce"}>
            <p>
              E-commerce services are diverse, with various benefits. Our Ecommerce Development services help you grow your business online while also making it easy for users to interact with your products and services. We provide services for managing and creating online platforms for your e-commerce business. Our services are not only about building and maintaining an online store, but also incorporate inventory management, accounting and billing, email marketing, online payments and ticketing, and online listing.
            </p>
            <ul>
              <li>Custom Ecommerce Development</li>
              <li>Ecommerce Website Development</li>
              <li>Ecommerce App Development</li>
              <li>Ecommerce Design</li>
              <li>Support & Maintenance</li>
            </ul>
          </ServiceTab>
        </TabPanel>
      </Box>
    </Box>
  );
}

import React from "react";
import { Navigation } from './components/Navigation';
import { Hero } from './components/Hero';
import { WhoAreWe } from "./components/WhoAreWe";
import { ServicesTabs } from "./components/ServicesTabs";
import { OutsourcingModels } from "./components/OutsourcingModels";
import { Process } from "./components/Process";
import { Industries } from "./components/Industries";
import { OurTeam } from "./components/OurTeam";
import ContactForm from "./components/ContactForm";
import { Footer } from "./components/Footer";

function App() {
  return (
    <>
      <Navigation />
      <div style={{position: "relative", top: "120px", backgroundColor: "#fcfcfc"}}>
        <Hero />
        <WhoAreWe />
        <ServicesTabs />
        <OutsourcingModels />
        <Process />
        <Industries />
        <OurTeam />
        <ContactForm />
        <Footer />
      </div>
    </>
  );
}

export default App;

import React, { useState } from 'react';
import { Box, Button, MenuItem, TextField, List, ListItem } from '@mui/material';
import { SideBySideLayout } from './helpers/SideBySideLayout';

const countries = [
  {
    value: 'USA',
    label: 'United States',
  },
  {
    value: 'UK',
    label: 'United Kingdom',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  // Add more countries as needed
];

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    // You can send the form data to your backend or handle it in any other way
  };

  return (
    <section
      id="contactForm"
      class="container"
    >
      <SideBySideLayout
        sizeOfFirst={1}
        sizeOfSecond={1}
        sx={{
          marginTop: "50px",
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box padding="20px">
          <h2
            style={{
              margin: '0 0 15px 0',
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              lineHeight: '1.1',
              fontSize: '2.3rem',
            }}
          >
            Why Choose StackHub as Your Trusted Software Development Company?
          </h2>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: '1.1rem',
              lineHeight: '1.8',
              margin: '10px 0px',
            }}
          >
            Well, you would be thinking why choose StackHub for your precious business? We are a bespoke Software development company that offers agile software development solutions for your business!
          </p>
          <List className='contact-section-list'>
            <ListItem>Over a decade of serving customers</ListItem>
            <ListItem>Full Cycle software development company</ListItem>
            <ListItem>Best Agile Practices</ListItem>
            <ListItem>Satisfied Global Clientele</ListItem>
            <ListItem>High-quaListItemty services</ListItem>
            <ListItem>Cost-effective solutions</ListItem>
            <ListItem>Partnerships with renowned companies</ListItem>
            <ListItem>Use of modern technology for high-end solutions</ListItem>
            <ListItem>A diverse team of experts</ListItem>
          </List>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Box textAlign="center">
            <h4
              style={{
                fontSize: "1.5rem",
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Let's discuss your project
            </h4>
            <p>
              Submit your request and we ll get in touch within a day
            </p>
          </Box>
          <form onSubmit={handleSubmit} style={{padding: "20px"}}>
            <Box display="flex" flexDirection="row">
              <TextField
                style={{
                  marginRight: "20px",
                  display: "flex",
                  flexGrow: 1,
                }}
                required
                id="name"
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
              />
              <TextField
                style={{
                  display: "flex",
                  flexGrow: 1,
                }}
                required
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Box>
            <Box display="flex" flexDirection="row" marginTop="20px">
              <TextField
                style={{
                  marginRight: "20px",
                  flex: 1
                }}
                id="phone"
                name="phone"
                label="Phone Number"
                value={formData.phone}
                onChange={handleChange}
              />
              <TextField
                style={{
                  flex: 1,
                }}
                id="country"
                name="country"
                select
                label="Country"
                value={formData.country}
                onChange={handleChange}
              >
                {countries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box display="flex" flexDirection="row" margin="20px 0px">
              <TextField
                style={{
                  flex: 1,
                }}
                required
                id="message"
                name="message"
                label="Message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              display="flex"
              fullWidth
            >
              Send
            </Button>
          </form>
        </Box>
      </SideBySideLayout>
    </section>
  );
};

export default ContactForm;

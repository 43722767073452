import React from 'react';
import processImage from '../assets/images/process.svg';

export const Process = () => {
  return (
    <section id="processes" class="process-section">
      <div class="container">
        <div class="process-content">
          <div class="process-content-text">
            <h2>Our End-to-End Software Development Process</h2>
          </div>
          <img src={processImage} alt="logo" style={{marginRight: '6px'}} />
        </div>
    </div>
    </section>
  );
}

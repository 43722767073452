import * as React from 'react';
import { Box, Grid, Link } from '@mui/material';
import { scrollToSection } from './Navigation';

import c1 from '../assets/images/outsourcing/c1.svg';
import c2 from '../assets/images/outsourcing/c2.svg';
import c3 from '../assets/images/outsourcing/c3.svg';

const OutsourcingModelCard = ({children, heading, image}) => {
  return (
    <Grid className="outsourcing-card">
      <h2>{ heading }</h2>
      <Box className="outsourcing-card-content">
        <img src={image} alt='outsourcing-image' />
        { children }
      </Box>  
    </Grid>
  );
}

export const OutsourcingModels = () => {

  return (
    <Box
      id="models"
      className="container outsourcing-models"
    >
      <h1>Our Diversified Outsourcing Models</h1>
      <p>
        We at Stack Hub believe in diversity, therefore we do not stick to a single outsourcing model while providing software development outsourcing services. We understand that all businesses are different and that our business model shall be formed according to their needs and problems. We specialize in offering three business models, namely: Project Based Outsourcing, IT Staff Augmentation, and Dedicated Teams.
      </p>
      <Grid className='outsourcing-cards'>
        <OutsourcingModelCard heading='IT Staff Augmentation' image={c1}>
        <ul>
          <li>Hire a temporary staff provided</li>
          <li>Suitable for both long-term and short-term projects</li>
          <li>Enhances efficiencies</li>
          <li>Meet strict deadlines</li>
          <li>Costs optimization</li>
        </ul>
        </OutsourcingModelCard>
        <OutsourcingModelCard heading='Dedicated Dev Team' image={c2}>
          <ul>
            <li>Get your hands on a professional team</li>
            <li>Team of expert developers, engineers, and project managers</li>
            <li>Suitable for long-term projects</li>
            <li>Control costs with effective planning</li>
            <li>Flexible project scoping with complete control</li>
          </ul>
        </OutsourcingModelCard>
        <OutsourcingModelCard heading='Project Based Outsourcing' image={c3}>
          <ul>
            <li>End-to-End project handling by StackHub</li>
            <li>Fixed project scope</li>
            <li>Minimum client involvement</li>
            <li>Minimize risks and costs involved</li>
            <li>Accelerate project delivery</li>
          </ul>
        </OutsourcingModelCard>
        <Grid className="outsourcing-card outsourcing-card-contact">
          <h2>Connect with us and we will provide you with the best solutions!</h2>
          <Box display="felx">
            <Link
              sx={{textDecoration: "none", cursor: "pointer"}}
              onClick={()=>{scrollToSection("contactForm")}}
              className="contact-link"
            >
              Get Started
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import React from 'react';
import { Box } from '@mui/material';

export const SideBySideLayout = ({
  sizeOfFirst,
  sizeOfSecond,
  sx,
  children
}) => {
  return (
    <Box
      display="flex"
      width="100%"
      sx={sx}
    >
      <Box flex={sizeOfFirst}>
        {children[0]}
      </Box>
      <Box flex={sizeOfSecond}>
        {children[1]}
      </Box>
    </Box>
  );
}
import React from 'react';
import { Typography, Box, Link } from '@mui/material';
import logoNoBg from '../assets/images/logoNoBg.png';
import  {ReactComponent as FacebookIcon}  from '../assets/images/icons/facebook.svg';
import  {ReactComponent as LinkedinIcon}  from '../assets/images/icons/linkedin.svg';
import  {ReactComponent as InstagramIcon}  from '../assets/images/icons/instagram.svg';
import  {ReactComponent as YoutubeIcon}  from '../assets/images/icons/youtube.svg';
import  {ReactComponent as TwitterIcon}  from '../assets/images/icons/twitter.svg';
import { scrollToSection } from './Navigation';

const Row = ({children}) => {
  return (
    <Box sx={{
      flex: "1",
      padding: "0 15px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: "25px",
    }}>
      {children}
    </Box>
  );
}

const FooterLink = ({sectionId, text}) => {
  return (
    <Box sx={{
      display: "inline-block",
      color: "#333",
      textDecoration: "none",
      marginBottom: "10px",
    }}>
      <Link
        sx={{
          textDecoration: "none",
          cursor: "pointer",
        }}
        onClick={() => {scrollToSection(sectionId)}}>{text}
      </Link>
    </Box>
  );
}

const SocialIcon = ({
  link,
  children,
}) => {
  return (
    <Box
      sx={{
        width: "65px",
        height: "65px",
        backgroundColor: "rgba(10, 20, 47, 0.9)",
        borderRadius: "20px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        cursor: "pointer",
      }}
      onClick={() => window.open(link, '_blank')}
    >
      {children}
    </Box>
  );
}

export const Footer = () => {
  return (
    <Box
      id="footer" 
      sx={{
        padding: "50px 0",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{
        backgroudColor: "blue",
        minWidth: "700px",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
      }}>
        <Row>
          <img src={logoNoBg} alt="logo" width={'40px'} style={{marginRight: '6px'}} />
          <Typography variant="h6" sx={{ fontWeight: 700, color: 'black', fontSize: '1.5rem' }}>
            Stack
            <span style={{color: '#54D5C8'}}>hub</span>
          </Typography>
        </Row>
        <Row>
          <Box sx={{display: "flex", justifyContent: "space-between", width: "80%", "&  a": {color: "rgba(10, 20, 47, 0.9)"}}}>
            <FooterLink sectionId="whoAreWe" text="About" />
            <FooterLink sectionId="services" text="Services" />
            <FooterLink sectionId="processes" text="Process" />
            <FooterLink sectionId="models" text="Outsourcing Models" />
          </Box>
        </Row>
        <Row>
          <Box sx={{display: "flex", justifyContent: "space-between", width: "80%"}}>
            <SocialIcon link="https://www.facebook.com">
              <FacebookIcon fill='#FFF' />
            </SocialIcon>

            <SocialIcon link="https://www.linkedin.com">
              <LinkedinIcon fill='#FFF' />
            </SocialIcon>

            <SocialIcon link="https://www.instagram.com">
              <InstagramIcon fill='#FFF' />
            </SocialIcon>

            <SocialIcon link="https://www.twitter.com">
              <TwitterIcon fill='#FFF' />
            </SocialIcon>

            <SocialIcon link="https://www.youtube.com">
              <YoutubeIcon fill='#FFF' />
            </SocialIcon>
          </Box>
        </Row>
      </Box>
    </Box>
  );
};

import React from 'react';
import heroImage from '../assets/images/heroImage.svg';
import { Link } from '@mui/material';
import { scrollToSection } from './Navigation';

export const Hero = () => {
  return (
    <section id="hero" class="hero-section">
      <div class="container">
        <div class="hero-content">
          <div class="hero-content-text">
            <h4>One stop development solution</h4>
            <h1>We Build & Refine <span>smart</span> web solutions</h1>
            <p>A leading software services company in pakistan that brings together top-rated experts and latest technologies for developing high-performance end-to-end solutions.</p>
            <div className="hero-content-text-btns">
              <Link
                className="hero-content-text-btns-learn-more"
                onClick={() => scrollToSection('services')}
                sx={{ cursor: 'pointer' }}
              >
                Learn More &nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-chevron-right"><path d="M9 6l6 6l-6 6"></path></svg>
              </Link>
            </div>
          </div>
          <img src={heroImage} alt="logo" width={'40px'} style={{marginRight: '6px'}} />
        </div>
    </div>
    </section>
  );
}

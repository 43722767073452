import React from 'react';
import { Box } from '@mui/material';

import c1 from '../assets/images/cards/c1.svg';
import c2 from '../assets/images/cards/c2.svg';
import c3 from '../assets/images/cards/c3.svg';
import c4 from '../assets/images/cards/c4.svg';
import c5 from '../assets/images/cards/c5.svg';
import c6 from '../assets/images/cards/c6.svg';
import { Link } from '@mui/material';
import { scrollToSection } from './Navigation';

export const WhoAreWe = () => {
  return (
    <section id="whoAreWe" class="who-section">
      <div className="container">
        <Box className="who-content">
          <Box className="who-content-text">
            <h2>Who Are We?</h2>
            <p>
              Stack Hub, a distinguished Custom Software Development Company, stands as a prominent Product Design partner, committed to excellence. With a rich legacy spanning over four years, our journey has been marked by unwavering dedication and a relentless pursuit of innovation. From our modest beginnings, we have grown into a formidable force, fueled by ambitious goals and a steadfast commitment to quality.
            </p>
            <p>
              As a global player in the software development landscape, Stack Hub takes pride in successfully executing a diverse array of over 50+ projects, earning the trust and satisfaction of more than 30 esteemed clients worldwide  
            </p>
            <div class="who-content-text-btn">
              <Link
                sx={{textDecoration: "none", cursor: "pointer"}}
                onClick={()=>{scrollToSection("contactForm")}}
                class="who-content-text-btn-contact"
              >
                Connect with our experts &nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-chevron-right"><path d="M9 6l6 6l-6 6"></path></svg>
              </Link>
            </div>
          </Box>
          <Box className="who-content-cards">
            <div className='card'>
              <img src={c1} />
              <span>50+</span>
              Projects Delivered
            </div>
            <div className='card'>
              <img src={c2} />
              <span>140+</span>
              Communications
            </div>
            <div className='card'>
              <img src={c3} />
              <span>30+</span>
              Happy Clients
            </div>
            <div className='card'>
              <img src={c4} />
              <span>20+</span>
              Talented Experts
            </div>
            <div className='card'>
              <img src={c5} />
              <span>90,000+</span>
              Work Hours
            </div>
            <div className='card'>
              <img src={c6} />
              <span>10+</span>
              Teams Deployed
            </div>
          </Box>
        </Box>
    </div>
    </section>
  );
}

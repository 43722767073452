import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { Box, Link} from "@mui/material";

import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Logo } from "./Logo";

export const scrollToSection = (sectionId) => {
  if (sectionId) {
    const element = document.getElementById(sectionId);
    const elTop = element?.getBoundingClientRect()?.top ?? 0;
    let yOffset = -100; //px
    const y = elTop + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

export const Navigation = () => {
  const [open, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <AppBar position="static" className='top-bar' sx={{position: 'fixed', top: 0, overflow: 'hidden'}}>
        <Container className='app-bar-container' sx={{maxWidth: '1650px !important', paddingY: '5px'}}>
          <Toolbar className='app-bar-tool-bar'>
            <Logo />

            <nav>
              <div class="nav-bar common-nav">
                <ul class="navbar-links">
                  <li><Link className="home-link" onClick={()=>{scrollToSection("hero")}}>Home</Link></li>
                  <li><Link className="about-link" onClick={()=>{scrollToSection("whoAreWe")}}>About</Link></li>
                  <li><Link className="models-link" onClick={()=>{scrollToSection("services")}}>Services</Link></li>
                  <li><Link className="testi-link" onClick={()=>{}}>Testimonials</Link></li>
                  <li><Link className="team-link" onClick={()=>{scrollToSection("team")}}>Our Team</Link></li>
                  <li><Link className="contact-link" onClick={()=>{scrollToSection("contactForm")}}>LETS TALK</Link></li>
                </ul>
              </div>
            </nav>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              sx={{
                height: "40px",
                display: {
                  md: "none",
                  sm: "block"
                }
              }}
            >
              <MenuIcon sx={{color: '#54D5C8'}} />
            </IconButton>

            {/* The outside of the drawer */}
            <Drawer
              //from which side the drawer slides in
              anchor="right"
              //if open is true --> drawer is shown
              open={open}
              //function that is called when the drawer should close
              onClose={toggleDrawer(false)}
              //function that is called when the drawer should open
              onOpen={toggleDrawer(true)}
            >
              {/* The inside of the drawer */}
              <Box
                className="common-nav"
                sx={{
                  p: 2,
                  pr: 12,
                  width: '100%',
                  height: 1
                }}
              >
                {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                <IconButton sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
                  <CloseIcon sx={{color: '#54D5C8'}} />
                </IconButton>

                <Divider sx={{ mb: 2 }} />

                <Box sx={{ mb: 2 }}>
                  <ListItemButton>
                    <ListItemText primary="Home" />
                  </ListItemButton>

                  <ListItemButton>
                    <ListItemText primary="About" />
                  </ListItemButton>

                  <ListItemButton>
                    <ListItemText primary="Services" />
                  </ListItemButton>

                  <ListItemButton>
                    <ListItemText primary="Testimonials" />
                  </ListItemButton>


                  <ListItemButton>
                    <ListItemText primary="Our Team" />
                  </ListItemButton>

                  <ListItemButton>
                    <ListItemText primary="Contact" />
                  </ListItemButton>
                </Box>
              </Box>
            </Drawer>
          </Toolbar>
        </Container>
      </AppBar>
  );

}
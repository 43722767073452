import React from 'react';
import HelthcareImage from '../assets/images/industries/Healthcare.svg';
import ConstructionImage from '../assets/images/industries/Construction.svg';
import LogisticImage from '../assets/images/industries/Logistics.svg';
import FoodImage from '../assets/images/industries/Food.svg';
import OilImage from '../assets/images/industries/Oil.svg';
import AccountingImage from '../assets/images/industries/Accounting.svg';
import EducationImage from '../assets/images/industries/Education.svg';
import TransportationImage from '../assets/images/industries/Transportation.svg';

export const Industries = () => {
  return (
    <section id="industries" class="industries-section">
      <div class="container">
        <div class="industries-content">
          <div class="industries-content-text center">
            <h2>Industries we work in</h2>
            <p>We focus our software development services in diverse sectors where we have wide-ranging experience and exclusive industry knowledge</p>
          </div>
        </div>

        <div class="industries-list">
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={EducationImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Education</h3>
            </div>
          </div>
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={TransportationImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Transportation</h3>
            </div>
          </div>
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={LogisticImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Logistics</h3>
            </div>
          </div>
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={FoodImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Food</h3>
            </div>
          </div>
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={ConstructionImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Construction</h3>
            </div>
          </div>
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={OilImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Oil and Gas</h3>
            </div>
          </div>
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={HelthcareImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Healthcare</h3>
            </div>
          </div>
          <div class="industries-list-item">
            <div class="industries-list-item-img">
              <img src={AccountingImage} alt="industries" />
            </div>
            <div class="industries-list-item-text">
              <h3>Accounting</h3>
            </div>
          </div>
        </div>

    </div>
    </section>
  );
}

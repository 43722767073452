import logoNoBg from '../assets/images/logoNoBg.png';
import React from "react";
import { Typography } from "@mui/material";

export const Logo = () => {
  return (
    <>
      <img src={logoNoBg} alt="logo" width={'40px'} style={{marginRight: '6px'}} />
      <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 700, color: 'black', letterSpacing: '2px', fontSize: '1.5rem' }}>
        Stack
        <span style={{color: '#54D5C8', letterSpacing: '1px'}}>HUB</span>
      </Typography>
    </>
  )
}
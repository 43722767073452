import React from "react";
import { Grid, Box } from "@mui/material";
import OwaisImg from '../assets/images/team/Owais.jpeg';
import BilalImg from '../assets/images/team/Bilal.jpeg';
import TaimoorImg from '../assets/images/team/Taimoor.jpeg';
import HaseebImg from '../assets/images/team/Haseeb.jpeg';

const TeamMember = ({name, designation, image}) => {
  return (
    <Grid
      item xs={3}
      style={{
        display: "flex",
      }}
    >
      <Box
        padding="10px"
        textAlign="center"
      >
        <img 
          style={{
            display: "block",
            width: "75%",
            border: "3px solid #54D5C8",
            borderRadius: "50%",
            margin: "0 auto",
          }}
          src={image}
        />
        <h3>{name}</h3>
        <h4
          style={{
            color: "#2D7068",
          }}
        >
          {designation}
        </h4>
      </Box>
    </Grid>
  );
}

export const OurTeam = () => {
  return (
    <section
      id="team"
      class="container"
    >
      <div
        class="industries-content"
        style={{
          marginTop: "50px",
        }}
      >
        <div class="industries-content-text center">
          <h2>Our Team</h2>
          <p>We employee professionals who work round the clock to ensure only the best services are provided to our clients.</p>
        </div>
      </div>
      <Grid
        spacing={3}
        container
        sx={{
          marginTop: "20px",
        }}
      >
        <TeamMember name="Owais Raza" designation="CEO" image={OwaisImg} />
        <TeamMember name="Bilal Raza" designation="CCO" image={BilalImg} />
        <TeamMember name="Taimoor Ahmed" designation="VP of Engineernig" image={TaimoorImg} />
        <TeamMember name="Haseeb Ahmed" designation="Director of Engineernig" image={HaseebImg} />
      </Grid>
    </section>
    );
}
  
  